import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Chip,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { get, update } from "../../service/apiService";
import ConfirmSnackBar from "../snackbar/ConfrimSnackBar";

function SubscriptionDialog(props: any) {
  const { onClose, open } = props;
  const [data, setData] = React.useState<any>();
  const userData = localStorage.getItem("userProfileDto");
  const userId = userData ? JSON.parse(userData).id : null;
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [confrimSubmit, setConfrimSubmit] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const formatDates = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const formattedStartDate = start.toLocaleDateString("ar");
    const formattedEndDate = end.toLocaleDateString("ar");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  React.useEffect(() => {
    // Your code here
    get(`Profile/%20${userId}/Subscription`)
      .then((res: any) => {
        setData(res);
        formatDates(res.startDate, res.endDate);
      })
      .catch((err) => {});
  }, []);
  const handleClose = () => {
    setConfrimSubmit(false);
    onClose();
  };
  const activeStateToArabic = (state: string) => {
    if (state === "Active") {
      return "نشط";
    } else {
      return "غير نشط";
    }
  };
  const handleCanacel = () => {
    update(`Profile/${userId}/unsubscribe`, {
      unsubscribeReason: "some reason",
      delete: false,
    })
      .then((res) => {
        setConfrimSubmit(true);
        setTimeout(() => {
          handleClose();
        }, 3000);
      })
      .catch((err) => {
        setConfrimSubmit(false);
        if (err.response?.message) {
          setError(err.response.message);
          return;
        } else {
          setError("حدث خطأ");
        }
        console.log(err);
      });
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="alert-dialog-title">{"حالة الإشتراك"}</DialogTitle>
      {data && (
        <div className="px-3 d-flex flex-column">
          <div className="mb-2">
            <span className="fw-bold mt">{`الحالة : `} </span>
            <span
              className={
                data.profileStatus === "Active"
                  ? "active-color"
                  : "danger-color"
              }
            >
              {activeStateToArabic(data.profileStatus)}
            </span>
          </div>
          <div className="mb-2">
            <span className="fw-bold ">{`عدد الايام : `}</span>
            <span>{data.days}</span>
          </div>
          <div className="mb-2">
            <span className="fw-bold ">{`تاريخ بداية الاشتراك : `}</span>
            <span>{startDate}</span>
          </div>
          <div className="mb-2">
            <span className="fw-bold ">{`تاريخ انتهاء الاشتراك : `}</span>
            <span>{endDate}</span>
          </div>
          <p className="text-danger"> {error}</p>
        </div>
      )}
      <DialogActions>
        <Button onClick={handleCanacel}> إلغاء الإشتراك</Button>
        <Button onClick={handleClose}>إغلاق</Button>
      </DialogActions>
      <ConfirmSnackBar
        open={confrimSubmit}
        text={"تم إلغاء اشتراكك"}
        duration={2000}
      />
    </Dialog>
  );
}

export default SubscriptionDialog;
