import React, { useEffect, useState } from "react";
const text = require("../resources/textFile/Privacytxt.txt");
function Privacy() {
  const [htmlDocument, setHtmlDocument] = useState<any>();

  useEffect(() => {
    fetch(text)
      .then((response: any) => response.text())
      .then((text) => {
        setHtmlDocument(<div dangerouslySetInnerHTML={{ __html: text }} />);
      });
  }, []);

  return <div>{htmlDocument}</div>;
}

export default Privacy;
