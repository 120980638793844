import * as React from "react";

import { get, post } from "../service/apiService";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function AdminDashboard() {
  const auth = { userName: "Admin", password: "Dietfit@123" };
  const [number, setNumber] = React.useState("");

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleLeave);

    post("identity/Login/dashboard", { ...auth })
      .then((response) => {
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("isAdmin", "yes");
        console.log("inside");
        get("/SubscriptionHistory")
          .then((response) => {
            console.log("inside inside ");
            console.log("here", response);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      window.removeEventListener("beforeunload", handleLeave);
    };
  }, []);

  const handleLeave = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("isAdmin");
  };
  const numberChange = (e) => {
    const value = e.target?.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setNumber(Number(value));
    }
  };

  return (
    <div>
      <Box sx={{ height: 400, width: "100%" }}>
        <TextField
          label="With normal TextField"
          id="outlined-start-adornment"
          sx={{ m: 1, width: "25ch" }}
          value={number}
          onChange={(e) => numberChange(e)}
          inputProps={{
            maxLength: 3,
          }}
          InputProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">kg</InputAdornment>
              ),
            },
          }}
        />
      </Box>
    </div>
  );
}

export default AdminDashboard;
