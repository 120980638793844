import { Button, TextField, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { patch, post, update } from "../service/apiService";
import ConfirmSnackBar from "../components/snackbar/ConfrimSnackBar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

function Support() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [confirmSubmit, setConfrimSubmit] = useState<boolean>(false);

  const sendMessage = () => {
    post("/Support", { phoneNumber, email, message, subject })
      .then((res) => {
        setConfrimSubmit(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-100 d-flex justify-content-center align-items-center mb-5">
      <div className="survey-container">
        <h3 className="text-center mt-5">الدعم</h3>
        <div className="info-body">
          <TextField
            id="outlined-read-only-input"
            className="mb-5"
            label="رقم الهاتف المحمول"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            id="outlined-read-only-input"
            className="mb-5"
            label="البريد الإلكتروني"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="outlined-read-only-input"
            className="mb-5"
            label="الموضوع"
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextareaAutosize
            minRows={3}
            aria-label="empty textarea"
            placeholder="الرسالة"
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            onClick={sendMessage}
            variant="contained"
            color="success"
            className=" mt-5 text-white"
          >
            إرسال
          </Button>
        </div>
        <ConfirmSnackBar open={confirmSubmit} />
      </div>
    </div>
  );
}

export default Support;
